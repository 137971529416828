.praises-section{
    background-color: #f5f5f5;
    padding: 50px 0;
    text-align: center;
    width: 90%;
    margin: 0 auto;
    justify-content: center;

}

.praise{
    border-radius: 10px;
} 