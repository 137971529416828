body{
    background-color: #f5f5f5;
    /* font-family: 'Roboto', sans-serif; */
    font-size: 1.5rem;
    color: #333;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.graphics {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    padding: 0 10px;
    margin-top: 10rem;
}

