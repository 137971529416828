#root {
  max-width: 100vw;
}

body, #root {
  margin: 0;
  padding: 0;
  width: 100vw;
}

body {
  overflow-x: hidden;
}


.App {
  text-align: center;
  font-family: 'Jost', sans-serif;
  margin: 0;
  padding: 0;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



@font-face {
  font-family: 'Cherolina';
  src: url('./fonts/Cherolina.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.black-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80vh;
  background-color: rgba(0, 0, 0, 0.799);
  z-index: 1;
}
.hero {
  /* fullsc reen  */
  width: 100vw;
  height: 80vh;
  /* center the content */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background image */
  background-image: url('./img/hero.jpg');

  /* background image react */
  background-size: cover;
  background-position: 0 -400px;
  background-repeat: no-repeat;
}

/* media query for ipad */
@media screen and (max-width: 2048px) {
  .hero {
    background-position: 0 -300px;
  }
}

/* media query for ipad */
@media screen and (max-width: 1800px) {
  .hero {
    background-position: 0 -200px;
  }
}

@media screen and (max-width: 1400px) {
  .hero {
    background-position: 0 -100px;
  }
}

@media screen and (max-width: 1200px) {
  .hero {
    background-position: 0 0;
  }
}


/* media query for hero */
@media screen and (max-width: 768px) {
  .hero {
    background-position: 0 30px;
  }
}


.hero-text {
  /* center the content */
  display: flex;
  align-items: center;
  justify-content: center;
  /* text */
  color: #fff;
  font-size: 3rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
  z-index: 2;
  letter-spacing: 30px;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Jost', sans-serif;
}

.hero-subtext{
  /* center the content */
  display: flex;
  align-items: center;
  justify-content: center;
  /* text */
  color: #fff;
  font-size: 5rem;
  font-weight: 300;
  margin: 0;
  padding: 0;
  /* text-transform: uppercase; */
  width: 0%;
  letter-spacing: 1px;
  z-index: 2;
  font-family: 'Cherolina', sans-serif;
  animation: write-cursive 2s linear forwards;
}

@keyframes write-cursive {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  transition: all 0.3s ease;
}

/* nav for mobile 1200 */
@media screen and (max-width: 1000px) {
  .nav {
    display: none;
  }
}

.nav-mobile {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  transition: all 0.3s ease;
  color: white;
}

.nav-mobile {
  display: none;
}


#menu {
  
}

.logo-subtext-mobile


.scrolled {
  background-color: rgba(0, 0, 0, 0.799);
}



.logo {
  color: #fff;
  padding: 0 2rem;
  justify-content: space-between;
}

.logo-text {
  padding: 0;
  margin: 0;
  /* center the content */
  display: flex;
  align-items: center;
  justify-content: center;
  /* text */
  color: #ffffff00;
  font-size: 1rem;
  font-weight: 600;
  z-index: 2;
  letter-spacing: 15px;
  text-transform: uppercase;
  text-align: left;
}

.logo-subtext {
  padding: 0;
  margin: 0;
  /* center the content */
  display: flex;
  align-items: center;
  justify-content: center;
  /* text */
  color: #ffffff00;
  font-size: 2rem;
  font-weight: 300;
  /* text-transform: uppercase; */
  letter-spacing: 1px;
  z-index: 2;

  font-family: 'Cherolina', sans-serif;
}

.nav-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  padding: 0 2rem;
}



.nav-link {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 0 2rem;
  transition: all 0.3s ease;
  text-decoration: none;
  /* mouse pointer */
  cursor: pointer;


}

@media screen and (max-width: 1200px) {
  .nav-links {
    padding: 0 1rem;
    width: 30%;
  }

  .nav-link{
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 0 1rem;
  }
  
}

/* nav for mobile 1200 */
@media screen and (max-width: 1000px) {
  .nav-mobile {
    display: flex;
    background-color: #282c34;
  }

  .logo{
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .logo-text {
    color: #fff;
    font-size: .75rem;
    display: block;
  }

  .logo-subtext {
    color: #fff;
    font-size: 2rem;
    display: block;
    text-align: left;
  }
}

.hidden {
  display: none;
}

/* PHOTOGRPHY PAGE */


.gallery-section{
  margin: 10rem 4rem;
  /* align-content: center;
  justify-content: center;
  text-align: center; */
}

/* mobile gallery-section margin */
@media screen and (max-width: 1200px) {
  .gallery-section{
    margin: 10rem 1rem;
  }
}

.back-btn{
  color:rgba(222, 159, 96, 0.799);
  font-size: 1rem;
  /* font-weight: 300; */
  /* text-transform: uppercase; */
  letter-spacing: 1px;
  z-index: 2;
  /* font-family: 'Cherolina', sans-serif; */
  /* border: 1px solid rgba(222, 159, 96, 0.799); */
  border: 1px solid rgba(222, 159, 96, 0);
  border: 0;
  background: none;
  /* border-radius: 5px; */
  padding: .2rem 3rem;
  margin: 2rem;
}

.back-btn:hover{
  color: #fff;
  background: #de9f60cc;
  border: 1px solid rgba(222, 159, 96, 0.799);
  border: 0;
  /* pointer */
  cursor: pointer;
  padding: .2rem 3rem;
  margin: 2rem;
}

.back-btn::before{
  content: "-";
  margin-right: 1rem;
}

.back-btn::after{
  content: "-";
  margin-left: 1rem;
}


.gallery {
  /* align-content: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto; */
  /* /* display: grid; */
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 60vh;
  grid-gap: 1px;
}

/* grid media queries */

@media screen and (max-width: 1200px) {
  .gallery {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 900px) {
  .gallery {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .gallery {
    grid-template-columns: repeat(1, 1fr);
  }
}

.gallery-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease;
}


.gallery-item {
  padding: 5px;
  text-align: center;
  text-decoration: none;
  border-bottom: none;
  transition: all 0.3s ease;
  color:rgba(0, 0, 0, 0.799)
}

.section-header {
  font-size: 3rem;
  font-weight: 300;
  color:rgba(222, 159, 96, 0.799);
  letter-spacing: 1px;
}


.about-section {
  align-content: center;
  justify-content: center;
  text-align: center;
  position: relative;
  width: 100%;
  height: 100vh;
  margin: 5rem 0;
  overflow: visible;
  min-height: 300px;

}

.about-img-container{
  position: relative;
}

.about-img-accent{
  width: 80%;
  height: 90%;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 5%;
  background-color: rgba(183, 156, 156, 0.827);
}

.about-img{
  width: 75%;
  z-index: 10;
  margin-top: 4rem;
  /* position: absolute; */
  /* bottom: 5%; */
  /* left: 20%; */
}

@media screen and (max-width: 600px) {
  .about-img{
    width: 80%;
    height: 70%;
    z-index: 10;
    margin-top: 4rem;
    /* position: absolute; */
    /* bottom: 5%; */
    /* left: 20%; */
  }
}

/* media query for about-img width */



.about-text-container{
  text-align: left;
}

.about-text {
  text-align: left;
  /* position: absolute;
  top: 20%;
  left: 55%; */
  width: 40%;
  overflow: auto;
}

.about-text1 {
  font-family: 'Cherolina', sans-serif;
  font-size: 10rem;
  font-weight: 300;
  padding: 0;
  margin: 0;
}

.about-text2 {
  font-size: 2rem;
  font-weight: 300;
  color:rgba(183, 156, 156, 0.827);
  letter-spacing: 5px;
}

.about-text3 {
  font-size: 1.2rem;
  font-weight: 300;
  letter-spacing: 1px;
  margin-bottom: 2rem;
  text-align: left;
}

.about-text-location{
  font-size: 1.2rem;
  font-weight: 100;
  letter-spacing: 1px;
  margin-bottom: 2rem;
  margin-left: 1rem;
  text-align: left;
}

.contact-me{
  align-content: center;
  justify-content: center;
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(183, 156, 156, 0.827);
  color: #f6f6f6;
  /* margin: 5rem 0; */
  padding: 1rem 0;
  overflow: hidden;
}

.contact-me-header{
  font-size: 3rem;
  font-weight: 300;
  letter-spacing: 1px;
  padding: 1rem 2rem; 
  background: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

}

.contact-me-header-span{
  font-weight: 300;
  letter-spacing: 1px;
  padding: 1rem 2rem; 
  border: 2px solid #f6f6f6;
  background: none;
  border-radius: 1px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
}

.contact-me-text1{
  font-family: 'Cherolina', sans-serif;
  font-size: 8rem;
  font-weight: 300;
  padding: 0;
  margin: 0;
}

.contact-me-button {
  color: #f6f6f6;
  font-size: 1rem;
  /* font-weight: 300; */
  /* text-transform: uppercase; */
  letter-spacing: 1px;
  z-index: 2;
  /* font-family: 'Cherolina', sans-serif; */
  border: 2px solid #f6f6f6;
  background: none;
  border-radius: 1px;
  padding: 1rem 2rem;
  margin: 2rem;
}

.contact-me-button:hover{
  color: rgba(183, 156, 156, 0.827);
  background: #f6f6f6;
  border: 2px solid #f6f6f6;
  /* pointer */
  cursor: pointer;
}

.contact-me-grid-image{
  position: relative;
  width: 100%;
  height: 100%;
}

.background-contact{
  position: absolute;
  top: 10%;
  left: 10%;
  width: 60%;
  height: 100%;
  background-color: rgb(230, 230, 230);
  z-index: 1;
}
.contact-me-img{
  position: relative;
  top: 10px;
  left: 0;
  width: 60%;
  height: 80%;
  object-fit: cover;
  /* center */
  /* margin: 4rem auto; */
  z-index: 5;
}
