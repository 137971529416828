/*  */
.contact-me-social{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

.contact-me-social-icon{
  font-size: 2rem;
  color: #f6f6f6;
  transition: all 0.3s ease-in-out;
    margin: 0 1rem;
}