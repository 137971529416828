.praise-name {
	font-size: 3rem;
	font-weight: 500;
	font-family: "Cherolina", sans-serif;
	margin: 2rem;
	color: rgb(183, 156, 156);
	margin-bottom: 0.5rem;
}

.praise-date {
	font-size: 1rem;
	font-weight: 400;
	margin-bottom: 0.5rem;
	/* grey */
	color: #808080;
	margin: 2rem;
}

.praise-text {
	font-size: 1rem;
	font-weight: 400;
	margin-bottom: 0.5rem;
	/* width: 50%; */
	/* center */
	margin: 2rem;
}
