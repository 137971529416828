.graphic-section {
    margin: 4rem 4rem;
    /* height: 80vh; */
    /* align-content: center;
    justify-content: center;
    text-align: center; */
}

.graphic-section-details{
    margin: 4rem 4rem;
    /* height: 80vh; */
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
}

.graphic-section-details-header {
    font-size: 2rem;
    font-weight: 300;
    color:rgba(222, 159, 96, 0.799);
    letter-spacing: 1px;
  }

  .graphic-section-details-description{
    font-size: 1.5rem;
    font-weight: 300;
    color:rgba(0, 0, 0, 0.799);
    letter-spacing: 1px;
    /* margin: 2rem 2rem; */
  }

  /* media query for phones */
  @media only screen and (max-width: 600px) {
    .graphic-section-details-header {
      font-size: 1.5rem;
    }
    .graphic-section-details-description{
      font-size: 1rem;
      margin: 0
    }

    .graphic-section-details{
      margin: 0;
    }
  }