.rachel-logo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin: 0 0;
    align-content: center; 
    justify-content: center;
}


.rachel-logo-text {
    padding: 0;
    margin: 0;
    /* center the content */
    display: flex;
    align-items: center;
    justify-content: center;
    /* text */
    color: #171717;
    font-size: 2rem;
    font-weight: 600;
    z-index: 2;
    letter-spacing: 15px;
    text-transform: uppercase;
    text-align: left;
  }
  
  .rachel-logo-subtext {
    padding: 0;
    margin: 0;
    /* center the content */
    display: flex;
    align-items: center;
    justify-content: center;
    /* text */
    color: #171717;
    font-size: 10rem;
    font-weight: 300;
    /* text-transform: uppercase; */
    letter-spacing: 1px;
    z-index: 2;
  
    font-family: 'Cherolina', sans-serif;
  }

.rachel-animation {
    animation: color-change 2s infinite;
}


@keyframes color-change {
    0% {
        color: #171717;
    }
    50% {
        color: #9e9e9e;
    }
    100% {
        color: #171717;
    }
}